import { css } from '@emotion/react'

export const cssPadding = {
  all: (val: number) => css`
    padding: ${val}px;
  `,
  top: (val: number) => css`
    padding-top: ${val}px;
  `,
  right: (val: number) => css`
    padding-right: ${val}px;
  `,
  bottom: (val: number) => css`
    padding-bottom: ${val}px;
  `,
  left: (val: number) => css`
    padding-left: ${val}px;
  `,
  x: (val: number) => css`
    padding-right: ${val}px;
    padding-left: ${val}px;
  `,
  y: (val: number) => css`
    padding-top: ${val}px;
    padding-bottom: ${val}px;
  `
}

export const cssMargin = {
  all: (val: number) => css`
    margin: ${val}px;
  `,
  top: (val: number) => css`
    margin-top: ${val}px;
  `,
  right: (val: number) => css`
    margin-right: ${val}px;
  `,
  bottom: (val: number) => css`
    margin-bottom: ${val}px;
  `,
  left: (val: number) => css`
    margin-left: ${val}px;
  `,
  x: (val: number) => css`
    margin-right: ${val}px;
    margin-left: ${val}px;
  `,
  y: (val: number) => css`
    margin-top: ${val}px;
    margin-bottom: ${val}px;
  `
}

export const cssFullWidth = css`
  width: 100%;
`

export const cssFlexJustifyCenter = css`
  display: flex;
  justify-content: center;
`

export const cssFlexAlignCenter = css`
  display: flex;
  align-items: center;
`

export const cssFlexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const cssFlexColumn = css`
  display: flex;
  flex-direction: column;
`

export const cssFlexItemFill = css`
  flex: 1 1 auto;
`

export const cssGap = (gap: number) => css`
  gap: ${gap}px;
`

export const cssFontSize = (val: number) => css`
  font-size: ${val}px;
`
export const visuallyHidden = css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`